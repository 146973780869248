import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Wrap from "./wrap"
import LogoSVG from "../svg/logo.inline.svg"

const Header = ({ siteTitle }) => {
  const inputRef = useRef()

  const toggleMenu = () => {
    inputRef.current.click()
  }

  return (
    <header className="sticky top-0 z-40 w-full bg-white">
      <Wrap
        paddingY="0"
        className="flex flex-col lg:flex-row lg:justify-between"
      >
        <div className="flex justify-between items-center py-2 lg:p-0">
          <Link to="/" className="block no-underline">
            <LogoSVG />
          </Link>
          <label
            htmlFor="menuToggle"
            className="lg:hidden px-2 no-underline border border-black text-black cursor-pointer"
          >
            Menu
          </label>
        </div>
        <input
          type="checkbox"
          id="menuToggle"
          ref={inputRef}
          className="hidden"
        />
        <nav className="nav flex lg:block flex-1" onClick={toggleMenu}>
          <ul className="pb-2 lg:pb-0 lg:flex lg:justify-between lg:text-center lg:ml-10">
            <li className="lg:inline-block flex-1">
              <Link
                to="/#features"
                className="inline-block py-2 no-underline text-theme-body"
              >
                Features
              </Link>
            </li>
            <li className="lg:inline-block flex-1">
              <Link
                to="/#specifications"
                className="inline-block py-2 no-underline text-theme-body"
              >
                Specifications
              </Link>
            </li>
            <li className="lg:inline-block flex-1">
              <a
                href="https://discordapp.com/invite/n7fAKJE"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="inline-block py-2 no-underline text-theme-body"
              >
                Community
              </a>
            </li>
            <li className="lg:inline-block flex-1">
              <a
                href="https://medium.com/coincollect"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="inline-block py-2 no-underline text-theme-body"
              >
                Blog
              </a>
            </li>
            <li className="lg:inline-block flex-1">
              <Link
                to="/coin"
                className="inline-block py-2 no-underline text-blue"
              >
                CCL Coin
              </Link>
            </li>
          </ul>
        </nav>
      </Wrap>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
