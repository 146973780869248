import React from "react"
import PropTypes from "prop-types"

function Wrap({ children, className, maxWidth, paddingY }) {
  return (
    <div className={`w-full ${maxWidth} mx-auto ${paddingY} px-4 ${className}`}>
      {children}
    </div>
  )
}

Wrap.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
  paddingY: PropTypes.string,
}

Wrap.defaultProps = {
  maxWidth: `max-w-5xl`,
  paddingY: `py-16`,
}

export default Wrap
