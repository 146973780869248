import React from "react"
import Wrap from "./wrap"

import BTC from "../svg/coins/BTC.inline.svg"
import BTH from "../svg/coins/BTH.inline.svg"
import CCL from "../svg/coins/CCL.inline.svg"
import KMD from "../svg/coins/KMD.inline.svg"
import LTC from "../svg/coins/LTC.inline.svg"
import VRS from "../svg/coins/VRS.inline.svg"

const Footer = () => (
  <footer className="bg-theme-yellow-light text-center">
    <Wrap>
      <h1
        className="h3"
        data-sal-duration="300"
        data-sal="zoom-in"
        data-sal-delay="300"
        data-sal-easing="slide-up"
      >
        Listed coins
      </h1>
      <div
        className="flex justify-center pt-3 md:pt-5 mb-8"
        data-sal-duration="300"
        data-sal="zoom-in"
        data-sal-delay="300"
        data-sal-easing="ease-out-bounce"
      >
        <CCL className="mx-3 md:mx-4" />
        <KMD className="mx-3 md:mx-4" />
        <VRS className="mx-3 md:mx-4" />
        <BTC className="mx-3 md:mx-4" />
        <LTC className="mx-3 md:mx-4" />
        <BTH className="mx-3 md:mx-4" />
      </div>
      <div
        data-sal-duration="600"
        data-sal="slide-right"
        data-sal-delay="300"
        data-sal-easing="ease-out-bounce"
      >
        <p className="mb-2 text-xl">Missing your favourite coin?</p>
        <a
          href="https://discordapp.com/invite/n7fAKJE"
          target="_blank"
          rel="noopener noreferrer nofollow"
          className="target-link"
        >
          Talk to us on Discord
        </a>
      </div>
    </Wrap>
  </footer>
)

export default Footer
