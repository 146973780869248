import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import ImageLogoAppstore from "../components/images/logo-appstore"
import ImageLogoPlaystore from "../components/images/logo-playstore"

const DownloadButtons = () => {
  return (
    <div className="mt-12 mb-8 flex flex-col sm:flex-row items-center sm:justify-center" style={{
      
    }}>
      <a
        href="https://apps.apple.com/us/app/coincollect/id1473471322"
        target="_blank"
        rel="noopener noreferrer nofollow"
        className="mx-2 mb-4 sm:lg-0 sm:mx-3 transition-duration hover:shadow-lg"
      >
        <ImageLogoAppstore />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.coincollect.app"
        target="_blank"
        rel="noopener noreferrer noffolow"
        className="mx-2 mb-4 sm:lg-0 sm:mx-3 transition-duration hover:shadow-lg"
      >
        <ImageLogoPlaystore />
      </a>
    </div>
  )
}

export default DownloadButtons
